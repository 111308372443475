import {FeeType} from '@wix/wix-events-commons-statics'
import {calculateTicketsTotalPrice} from '../services/payment'
import {SelectedTickets} from '../types'
import Ticket = wix.events.ticketing.TicketDefinition

export const getTicketById = (tickets: Ticket[], id: string) => tickets.find(ticket => ticket.id === id)

export const getTicketOrderIndexById = (tickets: Ticket[], id: string) => tickets.findIndex(ticket => ticket.id === id)

export const areAllTicketsSoldOut = (tickets: Ticket[]) => !tickets.find((ticket: Ticket) => !!ticket.limitPerCheckout)

export const isPaidTicketSelected = (tickets: Ticket[], selectedTickets: SelectedTickets) =>
  calculateTicketsTotalPrice(tickets, selectedTickets) > 0

export const isWixFeeTypeIsAdded = (ticket: Ticket) => ticket.wixFeeConfig?.type === FeeType.FEE_ADDED
